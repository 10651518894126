// @flow

import React from 'react'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import IntroTeaser from '../../components/IntroTeaser'
import Services from '../../components/Services'
import ServicesNav from '../../components/ServicePages/ServicesNav'

import messages from '../../components/ServicePages/ServicePages.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import pageTitle from '../../messages/pageTitles.lang'
import baseStyles from '../../components/ServicePages/ServicePages.scss'
import SEO from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { FormattedMessageSanitized } from '../../components/FormattedMessageSanitized'

const IntroImage = (
  <StaticImage src="../../images/services/subpages/concept-strategy.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/service_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const ServicePage = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(pageTitle.TitleConceptStrategy)}
        description={formatMessage(metaDescription.conceptStrategyDescription)}
        lang={locale}
      />
      <ServicesNav />
      <div className={styles.root}>
        <SubpageHeroTeaser title={formatMessage(messages.conceptStrategypageTitle)} image={HeroTeaserImage} />
        <IntroTeaser
          title={formatMessage(messages.conceptStrategyintroTitle)}
          copy={
            <div className={styles.text}>
              <p>
                <FormattedMessageSanitized {...messages.conceptStrategyIntroInfo} />
              </p>
              <h3 className={styles.subtitle}>{formatMessage(messages.conceptStrategyintroSubtitle)}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: formatMessage(messages.conceptStrategyList).replaceAll("'", '') }}
              />
            </div>
          }
          image={IntroImage}
          fullWidth
          btn={formatMessage(messages.allServicesBtn)}
          btnLink="/services/"
        />
      </div>
      <Services
        services={[
          'consulting',
          'conceptStrategy',
          'design',
          'development',
          'systemIntegration',
          'systemEngineering',
          'appDevelopment',
          'hcmsIntegration'
        ]}
      />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ServicePage)
